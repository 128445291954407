import useAsync from "../../useAsync";

import * as documentApi from "../../../services/documentApi";
import useToken from "../../useToken";

export default function useDocumentTypes() {
  const token = useToken();

  const {
    data: documentTypes,
    loading: documentTypesLoading,
    error: documentTypesError,
    act: getDocumentTypes,
  } = useAsync(() => documentApi.getDocumentTypes(token), false);

  return {
    documentTypes,
    documentTypesLoading,
    documentTypesError,
    getDocumentTypes,
  };
}
