import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IoChevronForward } from "react-icons/io5";

import useInspectors from "../../../hooks/api/inspection/useInspectors";
import toastUtils from "../../../utils/toast-utils";
import { Section } from "../../../components/Section";
import { Container, InspectorContainer } from "./styles";
import theme from "../../../styles/theme";
import ListWrapper from "../../../components/ListWrapper";
import { Directory } from "../styles";
import noPicProfile from "../../../assets/no-pic-profile.png";

export default function Inspectors() {
  const { getInspectors, inspectorsLoading } = useInspectors();
  const responseLength = 40;
  const [inspectors, setInspectors] = useState([]);
  const search = useLocation().search.replace("?search=", "");

  useEffect(() => {
    loadInspectors();
  }, []);

  useEffect(() => {
    handleInspectorSearch();
  }, [search]);

  async function loadInspectors() {
    let skip = inspectors.length;
    let take = responseLength;

    try {
      const response = await getInspectors({
        skip,
        take,
      });

      if (response) {
        setInspectors(inspectors.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleInspectorSearch() {
    if (!search) return loadInspectors();
    const result = inspectors.filter((inspector) =>
      inspector.name.toLowerCase().includes(search.toLowerCase())
    );
    setInspectors(result);
  }

  return (
    <Section title="Certificações/Vistoriadores e Analistas">
      <Container theme={theme}>
        <ListWrapper
          offset={responseLength}
          list={inspectors}
          loadList={loadInspectors}
          loading={inspectorsLoading}
        >
          {inspectors.map((inspector) => (
            <InspectorWrapper
              id={inspector.id}
              name={inspector.name}
              avatar={inspector.avatar}
              crea={inspector.crea}
              key={inspector.id}
            />
          ))}
        </ListWrapper>
      </Container>
    </Section>
  );
}

function InspectorWrapper({ id, name, avatar, crea }) {
  const navigate = useNavigate();

  return (
    <Directory theme={theme} onClick={() => navigate(`${id}`)}>
      <InspectorContainer>
        <img src={avatar ? avatar : noPicProfile} alt="Foto do usuário" />

        <h5>{name}</h5>

        <span className="mobile">|</span>

        <p>{`CREA-${crea.uf}: ${crea.number}`}</p>
        <IoChevronForward />
      </InspectorContainer>
    </Directory>
  );
}
