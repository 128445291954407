import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Section } from "../../../components/Section";
import generalUtils from "../../../utils/general-utils";
import { Container } from "./styles";
import Directories from "../../../components/Directories";
import theme from "../../../styles/theme";

export default function Certificates() {
  const search = useLocation().search.replace("?search=", "");
  const directories = [
    { text: "VISTORIAS", route: "vistorias" },
    { text: "ANÁLISES DOCUMENTAIS", route: "analises-documentais" },
    { text: "VISTORIADORES E ANALISTAS", route: "vistoriadores-analistas" },
    { text: "CERTIFICADOS EMITIDOS", route: "certificados-emitidos" },
  ];

  const [dirList, setDirList] = useState(directories);

  useEffect(() => {
    generalUtils.handleDirSearch({ search, directories, setDirList });
  }, [search]);

  return (
    <Section title="Certificações">
      <Container theme={theme}>
        <Directories dirList={dirList} />
      </Container>
    </Section>
  );
}
