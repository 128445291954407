import api from "./api";

export async function getDocuments(vesselId, token) {
  const response = await api.get(`/document/many/${vesselId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getDocumentTypes(token) {
  const response = await api.get("/document/type/many", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getDocument(documentId, token) {
  const response = await api.get(`/document/${documentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postDocument(body, token) {
  const response = await api.post("/document", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postDocumentType(body, token) {
  const response = await api.post("/document/type", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteDocument(documentId, token) {
  const response = await api.delete(`/document/${documentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
