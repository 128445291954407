import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Section } from "../../../components/Section";
import theme from "../../../styles/theme";
import useNotification from "../../../hooks/api/notification/useNotification";
import notificationUtils from "../../../utils/notification-utils";
import toastUtils from "../../../utils/toast-utils";
import { useEffect } from "react";
import { Container, Line } from "./styles";
import Loader from "../../../components/Loader";
import Button from "../../../components/forms/notification-form/Button";
import useSaveNotification from "../../../hooks/api/notification/useSaveNotification";

export default function Notification() {
  const { notificationId } = useParams();
  const navigate = useNavigate();
  const { getNotificationData, notificationDataLoading } = useNotification();
  const { saveNotification, saveNotificationLoading } = useSaveNotification();
  const [title, setTitle] = useState("Avisos");
  const [notification, setNotification] = useState(
    notificationUtils.emptyNotification
  );

  useEffect(() => {
    loadNotification();
  }, []);

  async function loadNotification() {
    try {
      const response = await getNotificationData(notificationId);

      if (response) {
        const date = notificationUtils.formatNotificationDate(
          response.createdAt
        );
        setNotification(response);
        setTitle(`Avisos/${date}`);

        if (notification.read === false)
          await saveNotification({
            notificationId: response.notificationId,
            userId: response.userId,
            message: response.message,
          });
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }
  return (
    <Section theme={theme} title={title}>
      <Container theme={theme}>
        {notificationDataLoading ? (
          <Loader />
        ) : (
          <>
            <pre>
              {notification.message.split("\n").map((line, index) => (
                <Line key={index}>{line.trimStart()}</Line>
              ))}
            </pre>
            <Button
              onClick={() => navigate(-1)}
              className="submit"
              title="Ok"
              enabled={!saveNotificationLoading}
            />
          </>
        )}
      </Container>
    </Section>
  );
}
