import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useCertificateFilters() {
  const token = useToken();

  const {
    data: certificateFilters,
    loading: certificateFiltersLoading,
    error: certificateFiltersError,
    act: getCertificateFilters,
  } = useAsync(() => inspectionApi.getCertificateFilters(token), false);

  return {
    certificateFilters,
    certificateFiltersLoading,
    certificateFiltersError,
    getCertificateFilters,
  };
}
