import useAsync from "../../useAsync";

import * as documentApi from "../../../services/documentApi";
import useToken from "../../useToken";

export default function useSaveDocument() {
  const token = useToken();

  const {
    loading: saveDocumentLoading,
    error: saveDocumentError,
    act: saveDocument,
  } = useAsync((data) => documentApi.postDocument(data, token), false);

  return {
    saveDocumentLoading,
    saveDocumentError,
    saveDocument,
  };
}
