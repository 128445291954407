import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useVesselReports() {
  const token = useToken();

  const {
    data: vesselReports,
    loading: vesselReportsLoading,
    error: vesselReportsError,
    act: getVesselReports,
  } = useAsync((data) => reportApi.getVesselReports(data, token), false);

  return {
    vesselReports,
    vesselReportsLoading,
    vesselReportsError,
    getVesselReports,
  };
}
