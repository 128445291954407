/* eslint-disable no-unused-vars */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { IoDocumentOutline } from "react-icons/io5";

import { Section } from "../../../../components/Section";
import useVessel from "../../../../hooks/api/vessel/useVessel";
import { useEffect } from "react";
import toastUtils from "../../../../utils/toast-utils";
import { Container, Edit, Form, Row, FormsWrapper } from "../styles";
import theme from "../../../../styles/theme";
import useDocument from "../../../../hooks/api/document/useDocument";
import useDocumentTypes from "../../../../hooks/api/document/useDocumentTypes";
import useSaveDocument from "../../../../hooks/api/document/useSaveDocument";
import documentUtils from "../../../../utils/document-utils";
import UserContext from "../../../../contexts/UserContext";
import Loader from "../../../../components/Loader";
import Label from "../../../../components/forms/document-form/Label";
import Input from "../../../../components/forms/report-form/Input";
import Select from "../../../../components/forms/enrollment-form/Select";
import DropInput from "../../../../components/forms/document-form/DropInput";
import Button from "../../../../components/forms/document-form/Button";

export default function Documentation() {
  const { documentId } = useParams();
  const newDocument = isNaN(documentId);
  const searchParams = new URLSearchParams(useLocation().search);
  const vesselId = JSON.parse(searchParams.get("vesselId"));
  const { userData } = useContext(UserContext);
  const allowed = userData.permission.label !== "DPC";
  const navigate = useNavigate();
  const { getVessel } = useVessel();
  const { getDocument, documentLoading } = useDocument();
  const { getDocumentTypes, documentTypesLoading } = useDocumentTypes();
  const { saveDocument, saveDocumentLoading } = useSaveDocument();
  const [title, setTitle] = useState("Embarcações");
  const [editing, setEditing] = useState(false);
  const [form, setForm] = useState(documentUtils.emptyDocument);
  const [document, setDocument] = useState(documentUtils.emptyDocument);
  const [types, setTypes] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    loadVesselName();
    loadDocumentTypes();
    loadDocument();
  }, []);

  async function loadVesselName() {
    if (!vesselId || isNaN(vesselId)) return;

    try {
      const response = await getVessel(vesselId);

      if (newDocument)
        setTitle(
          `Embarcações/${response.vessel.name}/Documentos/Documentação/Novo Documento`
        );
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadDocumentTypes() {
    try {
      const response = await getDocumentTypes();

      if (response) setTypes(response);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadDocument() {
    if (newDocument) return setEditing(true);

    try {
      const response = await getDocument(newDocument ? 0 : documentId);

      if (response) {
        setDocument(response);
        setFile({ name: response.name, file: response.file });
        setForm(response);
        setTitle(
          `Embarcações/${response.vessel.name}/Documentos/Documentação/${response.name}`
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (!file) return;

    const body = {
      documentId: form.id,
      name: form.name,
      file: form.file,
      vesselId,
      typeId: form.type.value,
    };

    try {
      await saveDocument(body);

      navigate(-1);

      toastUtils.toaster({
        message: "Documento salvo com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handlePdfView() {
    if (editing) return;

    navigate(`/painel/embarcação/documentos/pdf-preview/${document.name}`, {
      state: { fileSource: document.file },
    });
  }

  return (
    <Container theme={theme}>
      <Section title={title}>
        {documentLoading || documentTypesLoading ? (
          <Loader />
        ) : (
          <FormsWrapper>
            {!newDocument && allowed && (
              <Edit
                theme={theme}
                onClick={(e) => {
                  e.preventDefault();

                  if (editing) {
                    setForm(document);
                    setFile({ name: document.name, file: document.file });
                  } else if (!allowed) {
                    toastUtils.toaster({
                      message:
                        "Você não tem permissão para editar os dados deste documento!",
                      type: toastUtils.type.error,
                      position: toastUtils.position.topCenter,
                      theme: toastUtils.theme.colored,
                    });

                    return;
                  }

                  setEditing(!editing);
                }}
              >
                {editing ? "Cancelar" : "Editar"}
              </Edit>
            )}

            <Form className="documentForm" onSubmit={handleSubmit}>
              <div>
                <h3>Dados do Documento</h3>

                <Row>
                  <span className="name">
                    <Label id="name" text="Nome do Documento*" />
                    <Input
                      id="name"
                      placeholder="Nome do Documento"
                      type="text"
                      icon={IoDocumentOutline}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={form.name}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="type">
                    <Label id="type" text="Tipo de Documento*" />
                    <Select
                      id="type"
                      placeholder="Selecione..."
                      icon={IoDocumentOutline}
                      enabled={editing && allowed}
                      list={types}
                      selected={form.type}
                      required
                      submitted={submitted}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>
                </Row>

                <Row>
                  <span className="file">
                    <Label id="file" text="Arquivo*" />
                    <DropInput
                      file={file}
                      setFile={setFile}
                      setForm={setForm}
                      editing={editing}
                      submitted={submitted}
                      handlePdfView={handlePdfView}
                    />
                  </span>
                </Row>
              </div>

              <Button
                className="submit"
                title="Salvar"
                enabled={editing && allowed && !saveDocumentLoading}
              />

              {newDocument && (
                <Button
                  className="cancel"
                  title="Cancelar"
                  enabled={editing && allowed && !saveDocumentLoading}
                  type="button"
                  onClick={() => navigate(-1)}
                />
              )}
            </Form>
          </FormsWrapper>
        )}
      </Section>
    </Container>
  );
}
