import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { IoChevronForward } from "react-icons/io5";

import { Section } from "../../../../components/Section";
import useVessel from "../../../../hooks/api/vessel/useVessel";
import toastUtils from "../../../../utils/toast-utils";
import { Container } from "../../styles";
import theme from "../../../../styles/theme";
import { AddListItem, ListItem } from "../../styles";
import UserContext from "../../../../contexts/UserContext";
import useDocuments from "../../../../hooks/api/document/useDocuments";
import Loader from "../../../../components/Loader";

export default function Documentation() {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const allowed = userData.permission.label !== "DPC";
  const search = useLocation().search.replace("?search=", "");
  const { vesselId } = useParams();
  const { getVessel } = useVessel();
  const { getDocuments, documentsLoading } = useDocuments();
  const [title, setTitle] = useState("Embarcações");
  const [documents, setDocuments] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    loadDocuments();
  }, []);

  useEffect(() => {
    loadVesselName();
  }, [vesselId]);

  useEffect(() => {
    handleDocumentSearch();
  }, [search]);

  async function loadVesselName() {
    if (!vesselId) return;

    try {
      const response = await getVessel(vesselId);

      setTitle(`Embarcações/${response.vessel.name}/Documentos/Documentação`);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadDocuments() {
    try {
      const response = await getDocuments(vesselId);

      if (response) {
        setDocuments(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleDocumentSearch() {
    if (!search) return setFilteredList(documents);
    const newList = documents.filter((document) =>
      document.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredList(newList);
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        {allowed && (
          <AddListItem
            theme={theme}
            onClick={() =>
              navigate(
                `/painel/embarcacoes/documentos/documentacao/arquivo/novo?vesselId=${vesselId}`
              )
            }
          >
            <BsPlusCircleFill />
            <h5>Adicionar Documento</h5>
          </AddListItem>
        )}

        {documentsLoading ? (
          <Loader />
        ) : (
          <>
            {filteredList.map((document, index) => (
              <DocumentWrapper
                key={index}
                id={document.id}
                name={document.name}
                vesselId={vesselId}
              />
            ))}
          </>
        )}
      </Container>
    </Section>
  );
}

function DocumentWrapper({ id, name, vesselId }) {
  const navigate = useNavigate();

  return (
    <ListItem
      theme={theme}
      onClick={() =>
        navigate(
          `/painel/embarcacoes/documentos/documentacao/arquivo/${id}?vesselId=${vesselId}`
        )
      }
    >
      <div>
        <h5>{name}</h5>
        <IoChevronForward />
      </div>
    </ListItem>
  );
}
