import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { Container } from "./styles";
import theme from "../../../../styles/theme";

export default function NextButton({ text, path }) {
  const navigate = useNavigate();

  return (
    <Container theme={theme}>
      <h6>{text}</h6>
      <button type="button" onClick={() => navigate(path)}>
        <FaArrowRight />
      </button>
    </Container>
  );
}
