import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IoChevronForward } from "react-icons/io5";
import { BiFilter } from "react-icons/bi";
import { CgReorder } from "react-icons/cg";
import { BsPlusCircleFill } from "react-icons/bs";

import ListWrapper from "../../../components/ListWrapper";
import { Section } from "../../../components/Section";
import theme from "../../../styles/theme";
import { AddListItem, ListItem } from "../styles";
import { CertificateContainer, Container } from "./styles";
import toastUtils from "../../../utils/toast-utils";
import useCertificates from "../../../hooks/api/inspection/useCertificates";
import inspectionUtils from "../../../utils/inspection-utils";
import Select from "../../../components/forms/enrollment-form/Select";
import { ParamsWrapper } from "../Enrollment/styles";
import FilterSelector from "../../../components/FilterSelector";
import useCertificateFilters from "../../../hooks/api/inspection/useCertificateFilters";

export default function IssuedCertificate() {
  const searchParams = new URLSearchParams(useLocation().search);
  const vesselId = JSON.parse(searchParams.get("vesselId"));
  const { getCertificates, certificatesLoading } = useCertificates();
  const { getCertificateFilters } = useCertificateFilters();
  const responseLength = 40;
  const allowed = true; //definir permissões
  const navigate = useNavigate();
  const [certificates, setCertificates] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState(
    inspectionUtils.certificateEmptyFilters
  );
  const [filter, setFilter] = useState({});
  const [displayParams, setDisplayParams] = useState(
    inspectionUtils.certificateEmptyDisplayParams
  );
  const search = useLocation().search.replace("?search=", "");
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadCertificates();
    loadFilters();
  }, []);

  useEffect(() => {
    orderCertificates();
  }, [displayParams.order, filter]);

  useEffect(() => {
    handleCertificateSearch();
  }, [search]);

  useEffect(() => {
    handleFilter();
    handleCertificateSearch();
  }, [
    displayParams.company,
    displayParams.vessel,
    displayParams.inspector,
    displayParams.product,
  ]);

  useEffect(() => {
    const vessel = filters.vessels.find((item) => item.value === vesselId);

    if (vessel) {
      setDisplayParams({ ...displayParams, vessel: vessel });
    }
  }, [vesselId, filters]);

  async function loadCertificates() {
    const skip = certificates.length;
    const take = responseLength;

    try {
      const response = await getCertificates({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setCertificates(certificates.concat(response));
        setFilteredList(certificates.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadFilters() {
    try {
      const response = await getCertificateFilters();
      setFilters({
        ...filters,
        companies: filters.companies.concat(response.companies),
        vessels: filters.vessels.concat(response.vessels),
        inspectors: filters.inspectors.concat(response.inspectors),
        products: filters.products.concat(response.products),
      });
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderCertificates() {
    const skip = 0;
    const take = filter ? 10000 : responseLength;

    try {
      const response = await getCertificates({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setCertificates(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleFilter() {
    const params = Object.keys(displayParams)
      .filter(
        (key) =>
          Object.prototype.hasOwnProperty.call(displayParams[key], "column") &&
          displayParams[key].value !== 0
      )
      .reduce((acc, key) => {
        acc[displayParams[key].column] = displayParams[key].value;
        return acc;
      }, {});

    setFilter(params);
  }

  function handleCertificateSearch() {
    if (!search) return loadCertificates();
    const result = certificates.filter((certificate) =>
      certificate.name.toLowerCase().includes(search.toLowerCase())
    );
    setCertificates(result);
  }

  return (
    <Section title="Certificações/Certificados Emitidos">
      <Container theme={theme}>
        <ParamsWrapper displayFilters={displayFilters}>
          <Select
            id="order"
            icon={CgReorder}
            placeholder={"Ordenar por..."}
            enabled={true}
            list={inspectionUtils.certificateOrderList}
            selected={displayParams.order}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
          />

          <FilterSelector
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
          />

          <Select
            id="company"
            icon={BiFilter}
            placeholder={"Armador"}
            enabled={true}
            list={filters.companies}
            selected={displayParams.company}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="vessel"
            icon={BiFilter}
            placeholder={"Embarcação"}
            enabled={true}
            list={filters.vessels}
            selected={displayParams.vessel}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="inspector"
            icon={BiFilter}
            placeholder={"Vistoriador/Analista"}
            enabled={true}
            list={filters.inspectors}
            selected={displayParams.inspector}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="product"
            icon={BiFilter}
            placeholder={"Serviço"}
            enabled={true}
            list={filters.products}
            selected={displayParams.product}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        {allowed && (
          <AddListItem theme={theme} onClick={() => navigate(`novo`)}>
            <BsPlusCircleFill />
            <h5>Adicionar Embarcação</h5>
          </AddListItem>
        )}

        <ListWrapper
          offset={responseLength}
          list={certificates}
          loadList={loadCertificates}
          loading={certificatesLoading}
        >
          {filteredList.map((inspector) => (
            <CertificateWrapper
              id={inspector.id}
              name={inspector.name}
              avatar={inspector.avatar}
              crea={inspector.crea}
              key={inspector.id}
            />
          ))}
        </ListWrapper>
      </Container>
    </Section>
  );
}

function CertificateWrapper({ id, name }) {
  const navigate = useNavigate();

  return (
    <ListItem theme={theme} onClick={() => navigate(`${id}`)}>
      <CertificateContainer>
        <h5>{name}</h5>
        <IoChevronForward />
      </CertificateContainer>
    </ListItem>
  );
}
