const emptyDocument = {
  id: 0,
  name: "",
  file: "",
  vessel: { id: 0, name: "" },
  user: { id: 0, name: "" },
  type: { value: 0, label: "" },
};

const documentUtils = {
  emptyDocument,
};

export default documentUtils;
