import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MdOutlineFactCheck } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";

import { Divider, FormsWrapper, Row, Form } from "../../styles";
import { ListItem } from "../../../styles";
import toastUtils from "../../../../../utils/toast-utils";
import useInspectionRequests from "../../../../../hooks/api/inspection/useInspectionRequests";
import TextSelect from "../../../../../components/forms/inspection-form/TextSelect";
import { ChecklistsWrapper, Container } from "./styles";
import Textarea from "../../../../../components/forms/inspection-form/Textarea";
import useInspectionHistory from "../../../../../hooks/api/inspection/useInspectionHistory";
import NextButton from "../../../../../components/forms/inspection-form/NextButton";
import ConfirmationMessage from "../../../../../components/ConfirmationMessage";
import UserContext from "../../../../../contexts/UserContext";
import useInspectionData from "../../../../../hooks/api/inspection/useInspectionData";
import inspectionUtils from "../../../../../utils/inspection-utils";
import enrollmentUtils from "../../../../../utils/enrollment-utils";
import { Section } from "../../../../../components/Section";
import theme from "../../../../../styles/theme";
import AnalysisTimeline from "../../../../../components/forms/inspection-form/AnalysisTimeline";

export default function PerformedAnalysis() {
  const { inspectionId } = useParams();
  const [newInspection, setNewInspection] = useState(isNaN(inspectionId));
  const { userData } = useContext(UserContext);
  const allowed =
    userData.department.label === "ADMINISTRATIVO" ||
    userData.department.label === "DIRETORIA" ||
    userData.permission.label === "APROVADOR";
  const { getInspectionRequests } = useInspectionRequests();
  const { getInspectionData } = useInspectionData();
  const { getInspectionHistory } = useInspectionHistory();
  const [title, setTitle] = useState(
    "Certificações/Análises Documentais/Atribuídas"
  );
  const [editing, setEditing] = useState(false);
  const [inspection, setInspection] = useState(
    inspectionUtils.emptyInspectionData
  );
  const [requests, setRequests] = useState(inspectionUtils.emptyList);
  const [form, setForm] = useState(inspectionUtils.emptyAnalysisForm);
  const [schedules, setSchedules] = useState([]);
  const [submiting, setSubmiting] = useState(false);

  useEffect(() => {
    setNewInspection(isNaN(inspectionId));
  }, [inspectionId]);

  useEffect(() => {
    loadRequests();

    if (!newInspection) {
      loadInspectionData();
      loadSchedules();
    }
  }, []);

  useEffect(() => {
    if (form.request.value && newInspection) {
      loadInspectionData();
      setForm({
        ...inspectionUtils.emptyInspectionForm,
        request: form.request,
      });
    }
  }, [form.request.value]);

  async function loadInspectionData() {
    try {
      const response = await getInspectionData(
        newInspection ? form.request.value : inspectionId
      );

      if (response) {
        setInspection({
          ...response,
          pendingInspections: response.pendingInspections.filter(
            (item) => item.report.formObs === "ADP"
          ),
        });
        setForm({
          ...form,
          obs: response.obs,
          coordinator: {
            label: response.coordinator.name,
            value: response.coordinator.id,
          },
        });

        if (!newInspection) {
          setForm({
            ...form,
            request: response.request,
            obs: response.obs,
            coordinator: {
              label: response.coordinator.name,
              value: response.coordinator.id,
            },
          });

          setTitle(
            `${title}/${response.id} - ${response.vessel.name} (${response.company.name})`
          );
        }
      } else {
        setForm(inspectionUtils.emptyInspectionForm);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadRequests() {
    if (newInspection) {
      setTitle(`${title}/Nova Análise`);
      setEditing(true);
    }

    try {
      const response = await getInspectionRequests();

      if (response) setRequests(requests.concat(response));
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadSchedules() {
    try {
      const response = await getInspectionHistory(inspectionId);

      if (response) setSchedules(response);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value }) {
    if (name === "cpf")
      return setForm({ ...form, [name]: enrollmentUtils.cpfMask(value) });

    if (name === "phone" || name === "emergencyContactPhone")
      return setForm({ ...form, [name]: enrollmentUtils.phoneMask(value) });

    if (name === "number")
      return setForm({ ...form, [name]: value.toString() });

    setForm({ ...form, [name]: value });
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        <FormsWrapper>
          <Form className="inspectionForm">
            <div>
              <h3>Ordem de Serviço</h3>

              <Row>
                <span className="request no-label">
                  <TextSelect
                    id="request"
                    placeholder="Selecione..."
                    icon={MdOutlineFactCheck}
                    enabled={editing && allowed && newInspection}
                    list={requests}
                    selected={form.request}
                    required
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>

              <Row>
                <span className="textarea no-label">
                  <Textarea
                    id="obs"
                    placeholder="Observações..."
                    disabled={!(editing && allowed)}
                    value={form.obs}
                    required
                    form={form}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>
            </div>
          </Form>

          {!newInspection && (
            <>
              <Divider theme={theme} />
              <AnalysisTimeline schedules={schedules} />
            </>
          )}
        </FormsWrapper>

        {!newInspection && (
          <NextButton
            text="Visualizar checklists"
            setSubmiting={setSubmiting}
          />
        )}

        <ConfirmationMessage
          text="Selecione um checklist para visualizar"
          confirming={submiting}
          setConfirming={setSubmiting}
          okMessage={true}
        >
          <ChecklistsWrapper>
            {inspection.inProgressInspections
              .filter((item) => item.type === "INTERNA" && item.isFilled)
              .map((item, index) => (
                <Checklist
                  key={index}
                  id={item.report.id}
                  name={item.report.name}
                  obs={item.report.formObs}
                  inspectionId={inspectionId}
                />
              ))}
          </ChecklistsWrapper>
        </ConfirmationMessage>
      </Container>
    </Section>
  );
}

function Checklist({ id, name, obs, inspectionId }) {
  const navigate = useNavigate();

  return (
    <ListItem
      theme={theme}
      onClick={() =>
        navigate(
          `/painel/preencher-checklist/${inspectionId}?formId=${id}&new=false&type=${inspectionUtils.inspectionTypes.analysis}`
        )
      }
    >
      <div>
        <h5>{`${name} | ${obs}`}</h5>
        <FaCircleCheck />
      </div>
    </ListItem>
  );
}
