import useAsync from "../../useAsync";

import * as documentApi from "../../../services/documentApi";
import useToken from "../../useToken";

export default function useDocuments() {
  const token = useToken();

  const {
    data: documents,
    loading: documentsLoading,
    error: documentsError,
    act: getDocuments,
  } = useAsync((data) => documentApi.getDocuments(data, token), false);

  return {
    documents,
    documentsLoading,
    documentsError,
    getDocuments,
  };
}
