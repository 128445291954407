import { BiSolidCalendarEvent } from "react-icons/bi";
import { MdOutlineEdit } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiList } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { CgDetailsMore } from "react-icons/cg";
import { BsPeople } from "react-icons/bs";
import { IoMdAddCircle } from "react-icons/io";
import { useContext } from "react";

import { Button, Container } from "./styles";
import noPicProfile from "../../../../assets/no-pic-profile.png";
import theme from "../../../../styles/theme";
import calendarUtils from "../../../../utils/calendar-utils";
import EventInput from "../EventInput";
import { useState } from "react";
import EventDateTimePicker from "../EventDateTimePicker";
import dayjs from "dayjs";
import AddGuests from "../AddGuests";
import UserContext from "../../../../contexts/UserContext";

export default function EventInfo({
  event,
  setSelectedEvent,
  guestList,
  handleSubmit,
  setDeleting,
}) {
  const disableEdition = event.type !== "generic";
  const newEvent = event.id === 0;
  const { userData } = useContext(UserContext);
  const allowed = userData.enrollmentId === event.userId;
  const [currentEvent, setCurrentEvent] = useState(event);
  const [editing, setEditing] = useState(newEvent);
  const [addingGuests, setAddingGuests] = useState(false);

  function handleChange({ name, value }) {
    setCurrentEvent({ ...currentEvent, [name]: value });
  }

  return (
    <>
      <Container theme={theme} color={event.color}>
        <span className="buttons">
          {!newEvent && (
            <>
              <button
                type="button"
                disabled={disableEdition || editing || !allowed}
                onClick={() => setEditing(true)}
              >
                <MdOutlineEdit />
              </button>
              <button
                type="button"
                disabled={disableEdition || editing || !allowed}
                onClick={() => setDeleting(event)}
              >
                <RiDeleteBin6Line />
              </button>
            </>
          )}

          <button type="button" onClick={() => setSelectedEvent(null)}>
            <GrClose />
          </button>
        </span>

        <span className="title">
          <BiSolidCalendarEvent />
          <div className="details">
            {editing ? (
              <>
                <EventInput
                  id="title"
                  placeholder="Adicionar título*"
                  type="text"
                  disabled={!editing}
                  value={currentEvent.title}
                  onChange={(e) =>
                    handleChange({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
                <Date
                  currentEvent={currentEvent}
                  setCurrentEvent={setCurrentEvent}
                />
              </>
            ) : (
              <>
                <h3>{event.title}</h3>
                <p>{calendarUtils.formatEventDate(event)}</p>
              </>
            )}
          </div>
        </span>

        <span className="extra">
          {(event.location || newEvent || editing) && (
            <span>
              <IoLocationOutline />
              {editing ? (
                <EventInput
                  id="location"
                  placeholder="Adicionar local"
                  type="text"
                  disabled={!editing}
                  value={currentEvent.location}
                  onChange={(e) =>
                    handleChange({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              ) : (
                <p>{event.location}</p>
              )}
            </span>
          )}

          {(event.obs || newEvent || editing) && (
            <span>
              {event.type === "inspection" ? <FiList /> : <CgDetailsMore />}
              {editing ? (
                <EventInput
                  id="obs"
                  placeholder="Observações..."
                  type="text"
                  disabled={!editing}
                  value={currentEvent.obs}
                  onChange={(e) =>
                    handleChange({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              ) : (
                <p>{event.obs}</p>
              )}
            </span>
          )}

          {(event.guests?.length > 0 || newEvent || editing) && (
            <span className="guests">
              <BsPeople />
              {currentEvent.guests.map((guest) => (
                <div className="guest" key={guest.id}>
                  <img src={guest.picture || noPicProfile} alt="convidado" />
                  <p>{guest.name}</p>
                </div>
              ))}
              {event.type === "generic" && (
                <div className="guest add-guest">
                  <IoMdAddCircle onClick={() => setAddingGuests(true)} />
                  <p>Adicionar</p>
                </div>
              )}
            </span>
          )}
        </span>

        {editing && (
          <span className="cancel-save">
            <Button
              id="cancel"
              type="button"
              value="Cancelar"
              theme={theme}
              color={event.color}
              onClick={() => setSelectedEvent(null)}
            />

            <Button
              id="save"
              type="submit"
              value="Salvar"
              theme={theme}
              color={event.color}
              onClick={() => handleSubmit(currentEvent)}
              disabled={
                !currentEvent.title || !currentEvent.start || !currentEvent.end
              }
            />
          </span>
        )}
      </Container>

      {addingGuests && (
        <AddGuests
          guestList={guestList}
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          setAddingGuests={setAddingGuests}
        />
      )}
    </>
  );
}

function Date({ currentEvent, setCurrentEvent }) {
  return (
    <>
      <span className="allDay">
        <input
          type="checkbox"
          aria-label="Dia inteiro"
          value={currentEvent.allDay}
          onChange={() =>
            setCurrentEvent({ ...currentEvent, allDay: !currentEvent.allDay })
          }
          checked={currentEvent.allDay}
        />

        <label>Dia inteiro</label>
      </span>

      <span className="date">
        {currentEvent.allDay ? (
          <EventDateTimePicker
            id="start"
            type="date"
            disabled={false}
            required
            defaultValue={currentEvent.start}
            value={
              currentEvent.start
                ? dayjs(currentEvent.start)
                    .format("dddd, DD [de] MMMM")
                    .replace(/^\w/, (c) => c.toUpperCase())
                : null
            }
            form={currentEvent}
            setForm={setCurrentEvent}
          />
        ) : (
          <>
            <EventDateTimePicker
              id="start"
              type="datetime"
              disabled={false}
              required
              defaultValue={currentEvent.start}
              value={
                currentEvent.start
                  ? dayjs(currentEvent.start).format("DD/MM/YYYY HH:mm")
                  : null
              }
              form={currentEvent}
              setForm={setCurrentEvent}
            />
            <p>até</p>
            <EventDateTimePicker
              id="end"
              type="datetime"
              disabled={!currentEvent.start}
              required
              minDate={currentEvent.start}
              defaultValue={currentEvent.end}
              value={
                currentEvent.end
                  ? dayjs(currentEvent.end).format("DD/MM/YYYY HH:mm")
                  : null
              }
              form={currentEvent}
              setForm={setCurrentEvent}
            />
          </>
        )}
      </span>
    </>
  );
}
