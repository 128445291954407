import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import dayjs from "dayjs";

import useVessel from "../../../hooks/api/vessel/useVessel";
import toastUtils from "../../../utils/toast-utils";
import { Section } from "../../../components/Section";
import { Container } from "../styles";
import theme from "../../../styles/theme";
import { ListItem } from "../styles";
import Loader from "../../../components/Loader";
import useVesselReports from "../../../hooks/api/report/useVesselReports";
import reportUtils from "../../../utils/report-utils";

export default function VesselReports() {
  const search = useLocation().search.replace("?search=", "");
  const { vesselId } = useParams();
  const { getVessel } = useVessel();
  const { getVesselReports, vesselReportsLoading } = useVesselReports();
  const [title, setTitle] = useState("Embarcações");
  const [reports, setReports] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    loadReports();
  }, []);

  useEffect(() => {
    loadVesselName();
  }, [vesselId]);

  useEffect(() => {
    handleDocumentSearch();
  }, [search]);

  async function loadVesselName() {
    if (!vesselId) return;

    try {
      const response = await getVessel(vesselId);

      setTitle(`Embarcações/${response.vessel.name}/Relatórios`);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadReports() {
    try {
      const response = await getVesselReports(vesselId);

      if (response) {
        setReports(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleDocumentSearch() {
    if (!search) return setFilteredList(reports);
    const newList = reports.filter((report) =>
      report.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredList(newList);
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        {vesselReportsLoading ? (
          <Loader />
        ) : (
          <>
            {filteredList.map((report, index) => (
              <ReportWrapper key={index} report={report} />
            ))}
          </>
        )}
      </Container>
    </Section>
  );
}

function ReportWrapper({ report }) {
  const navigate = useNavigate();

  return (
    <ListItem
      theme={theme}
      onClick={() => navigate(reportUtils.setReportRoute(report))}
    >
      <div>
        <h5>{report.name}</h5>
        <p>{dayjs(report.date).format("DD/MM/YYYY")}</p>
      </div>
    </ListItem>
  );
}
