import { useLocation } from "react-router-dom";
import { Section } from "../../../../components/Section";
import PdfViewer from "../../../../components/PdfViewer";

export default function PdfViewerPage() {
  const location = useLocation();
  const { fileSource } = location.state || { fileSource: "" };
  const title = "Visualizar PDF";

  return (
    <Section title={title}>
      <PdfViewer fileSource={fileSource} />
    </Section>
  );
}
