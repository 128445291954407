import styled from "styled-components";

import { Directory } from "../styles";

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 245px);
  position: relative;

  p,
  pre {
    font-size: 20px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    line-height: 30px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
  }

  h4 {
    width: 100%;
    text-align: center;
    margin-top: 15%;
    font-size: 20px;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const ParamsWrapper = styled.span`
  display: flex;
  flex-direction: row;

  & > div {
    width: 240px;
    height: 50px;
    margin-right: 10px;
    border-radius: 20px;
    position: initial;

    .select {
      max-width: 190px !important;
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }

  #service {
    width: 500px;

    .select {
      max-width: 450px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 20px;

    & > div {
      width: 100%;
      margin-right: 0;

      .select {
        max-width: 100% !important;
      }

      .css-1u9des2-indicatorSeparator {
        display: none;
      }
    }

    .filter {
      display: ${({ displayFilters }) => (displayFilters ? "flex" : "none")};
    }

    #service {
      width: 100%;

      .select {
        max-width: 100%;
      }
    }
  }
`;

export const ListItem = styled(Directory)`
  width: 100%;
  max-height: 70px;
  border-radius: 10px;
  padding: 0 10px;
  margin: 5px 0;
  position: relative;
  flex-direction: row;

  div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    height: 100%;
    width: 90%;
    padding: 5px 0;
    overflow: hidden;
  }

  p {
    margin: 0;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 80px;
    max-width: 100%;
    color: ${({ theme, read }) =>
      read ? theme.COLORS.GRAY_100 : theme.COLORS.WHITE};
  }

  h6 {
    margin: 0;
    color: ${({ theme, read }) =>
      read ? theme.COLORS.GRAY_100 : theme.COLORS.WHITE};
  }

  svg {
    display: ${({ read }) => (read ? "none" : "initial")};
    color: ${({ theme }) => theme.COLORS.ORANGE};
    height: 20px;
    width: 20px;
    position: absolute;
    top: calc(50% - 10px);
    right: 10px;
  }

  @media (max-width: 767px) {
    height: 60px;

    p,
    pre {
      font-size: 18px;
    }

    h6 {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;

export const NotificationContainter = styled.div`
  display: flex;
  width: 100%;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: end;
  color: ${({ theme, disabled }) =>
    disabled ? theme.COLORS.GRAY_300 : theme.COLORS.GRAY_100};
  background-color: transparent;
  border: 0;
  font-size: 18px;
  margin-left: 10px;

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_300 : theme.COLORS.WHITE};
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
`;

export const DeleteWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
`;

export const Line = styled.div`
  margin: 0 0 10px;
  height: auto;
  display: flex;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
