import useAsync from "../../useAsync";

import * as documentApi from "../../../services/documentApi";
import useToken from "../../useToken";

export default function useDocument() {
  const token = useToken();

  const {
    data: document,
    loading: documentLoading,
    error: documentError,
    act: getDocument,
  } = useAsync((data) => documentApi.getDocument(data, token), false);

  return {
    document,
    documentLoading,
    documentError,
    getDocument,
  };
}
