import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdOutlineFactCheck } from "react-icons/md";
import { RiShipLine } from "react-icons/ri";
import { RxIdCard } from "react-icons/rx";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { FaHelmetSafety } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { BsCalendarEvent, BsHouse } from "react-icons/bs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import UserContext from "../../../../../contexts/UserContext";
import useInspectors from "../../../../../hooks/api/inspection/useInspectors";
import useInspectionData from "../../../../../hooks/api/inspection/useInspectionData";
import useScheduleInspection from "../../../../../hooks/api/inspection/useScheduleInspection";
import inspectionUtils from "../../../../../utils/inspection-utils";
import enrollmentUtils from "../../../../../utils/enrollment-utils";
import { Section } from "../../../../../components/Section";
import theme from "../../../../../styles/theme";
import { Address, Divider, Edit, FormsWrapper, Row } from "../../styles";
import { Form } from "../../styles";
import Label from "../../../../../components/forms/inspection-form/Label";
import { Container, ProductComment, ProductSelectorWrapper } from "./styles";
import Select from "../../../../../components/forms/inspection-form/Select";
import Input from "../../../../../components/forms/inspection-form/Input";
import Button from "../../../../../components/forms/inspection-form/Button";
import Textarea from "../../../../../components/forms/inspection-form/Textarea";
import DateTimePicker from "../../../../../components/DateTimePicker";
import toastUtils from "../../../../../utils/toast-utils";
import useAdddress from "../../../../../hooks/api/enrollment/useAddress";
import useInspectionDetails from "../../../../../hooks/api/inspection/useInspectionDetails";
import useDeleteDetails from "../../../../../hooks/api/inspection/useDeleteDetails";
import ConfirmationMessage from "../../../../../components/ConfirmationMessage";
import DateRangePicker from "../../../../../components/DateRangePicker";
import generalUtils from "../../../../../utils/general-utils";
import useInspectorInspections from "../../../../../hooks/api/inspection/useInspectorInspections";

export default function ScheduleDetails() {
  const { inspectionId } = useParams();
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const det = useLocation().search.replace("?det=", "");
  const allowed =
    userData.permission?.label === "MASTER" ||
    userData.permission?.label === "APROVADOR";
  const { getInspectors } = useInspectors();
  const { getInspectionData } = useInspectionData();
  const { getInspectionDetails } = useInspectionDetails();
  const { scheduleInspection } = useScheduleInspection();
  const { getAddress } = useAdddress();
  const { getInspectorInspections } = useInspectorInspections();
  const [title, setTitle] = useState("Certificações/Vistorias/Agendadas");
  const [editing, setEditing] = useState(false);
  const [inspection, setInspection] = useState(
    inspectionUtils.emptyInspectionData
  );
  const [inspectionBackup, setInspectionBackup] = useState({});
  const [inspectors, setInspectors] = useState([]);
  const [coordinators, setCoordinators] = useState(
    inspectionUtils.emptyInspector
  );
  const [form, setForm] = useState(inspectionUtils.emptyInspectionForm);
  const [formBackup, setFormBackup] = useState({});
  const [status, setStatus] = useState(0);
  const [inspectorBusydates, setInspectorBusyDates] = useState([]);
  const [dateConflictWarning, setDateConflictWarning] = useState(false);

  dayjs.locale("pt-br");

  useEffect(() => {
    loadInspectionData();
    loadCoordinators();
  }, []);

  useEffect(() => {
    const inspectorList = inspectionUtils.emptyInspector.concat(
      coordinators.filter((coordinator) =>
        form.reports.every((report) =>
          coordinator.qualifications.some(
            (qualification) => report.product.id === qualification.productId
          )
        )
      )
    );

    if (
      !inspectorList.find(
        (inspector) => inspector.value === form.inspector.value
      )
    )
      setForm({
        ...form,
        inspector: inspectionUtils.emptyInspectionForm.inspector,
      });

    setInspectors(inspectorList);
  }, [form.reports]);

  useEffect(() => {
    loadInspectorBusyDates();
  }, [form.inspector]);

  async function loadInspectionData() {
    try {
      const response = await getInspectionData(inspectionId);
      const details = await getInspectionDetails(det);

      if (response && details) {
        setInspection(response);
        setInspectionBackup(response);

        const result = {
          ...form,
          request: response.request,
          obs: response.obs,
          coordinator: {
            label: response.coordinator.name,
            value: response.coordinator.id,
          },
          inspector: {
            label: details.inspector.name,
            value: details.inspector.id,
          },
          date: details.date,
          startDate: new Date(details.startDate),
          endDate: new Date(details.endDate),
          cep: enrollmentUtils.cepMask(details.address.cep),
          uf: details.address.uf,
          city: details.address.city,
          neighborhood: details.address.neighborhood,
          street: details.address.street,
          number: details.address.number,
          complement: details.address.complement,
          reports: details.reports,
        };

        setForm(result);
        setFormBackup(result);
        setStatus(details.status);
        setTitle(
          `${title}/${response.id} - ${response.vessel.name} (${response.company.name})/Detalhes`
        );
      } else {
        setForm(inspectionUtils.emptyInspectionForm);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadCoordinators() {
    let skip = 0;
    let take = 1000;

    try {
      const response = await getInspectors({
        skip,
        take,
      });
      if (response) {
        setCoordinators(
          inspectionUtils.emptyInspector.concat(
            response.map((inspector) => ({
              ...inspector,
              label: inspector.name,
              value: inspector.crea.id,
            }))
          )
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadInspectorBusyDates() {
    if (!form.inspector.value) return;

    try {
      const response = await getInspectorInspections(form.inspector.value);

      setInspectorBusyDates(
        response.map((item) =>
          generalUtils.getDatesInterval(item.startDate, item.endDate)
        )
      );
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value }) {
    if (name === "number")
      return setForm({ ...form, [name]: value.toString() });

    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const body = {
      inspectionId: form.request.value,
      obs: form.obs,
      date: form.date,
      startDate: form.startDate,
      endDate: form.endDate,
      cep: enrollmentUtils.numberUnmask(form.cep),
      uf: form.uf.label,
      city: form.city,
      neighborhood: form.neighborhood,
      street: form.street,
      number: form.number,
      complement: form.complement,
      forms: form.reports.map((report) => ({
        detailId: report.detailId || 0,
        inspected: false,
        pendent: false,
        obs: report.obs,
        status: inspectionUtils.status.AGENDADA,
        inspectorId: form.inspector.value,
        formId: report.report.id,
      })),
    };

    try {
      await scheduleInspection(body);

      navigate("/painel/certificacoes/vistorias/agendadas");

      toastUtils.toaster({
        message: "Vistoria agendada com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleDateConflict(params) {
    setForm({
      ...form,
      startDate: null,
      endDate: null,
    });

    setDateConflictWarning(params);
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        <FormsWrapper>
          {allowed && status <= inspectionUtils.status.SERVICO_REALIZADO && (
            <Edit
              theme={theme}
              onClick={(e) => {
                e.preventDefault();

                if (editing) {
                  setForm(formBackup);
                  setInspection(inspectionBackup);
                } else if (!allowed) {
                  toastUtils.toaster({
                    message:
                      "Você não tem permissão para editar os dados desta vistoria!",
                    type: toastUtils.type.error,
                    position: toastUtils.position.topCenter,
                    theme: toastUtils.theme.colored,
                  });

                  return;
                }

                setEditing(!editing);
              }}
            >
              {editing ? "Cancelar" : "Editar"}
            </Edit>
          )}

          <Form className="inspectionForm" onSubmit={handleSubmit}>
            <div>
              <h3>Dados da Vistoria</h3>

              <Row>
                <span className="company">
                  <Label id="company" text="Armador" />
                  <Input
                    id="company"
                    placeholder="Armador"
                    type="text"
                    icon={RxIdCard}
                    disabled
                    value={inspection.company.name}
                  />
                </span>

                <span className="vessel">
                  <Label id="vessel" text="Embarcação" />
                  <Input
                    id="vessel"
                    placeholder="Embarcação"
                    type="text"
                    icon={RiShipLine}
                    disabled
                    value={inspection.vessel.name}
                  />
                </span>
              </Row>

              <Row>
                <span className="request">
                  <Label id="request" text="Ordem de Serviço" />
                  <Input
                    id="request"
                    placeholder="Ordem de Serviço"
                    type="text"
                    icon={MdOutlineFactCheck}
                    disabled
                    required
                    value={form.request.label}
                  />
                </span>
              </Row>

              <Row>
                <span className="textarea no-label">
                  <Textarea
                    id="obs"
                    placeholder="Observações..."
                    disabled={!(editing && allowed)}
                    value={form.obs}
                    required
                    form={form}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>
            </div>

            {inspection.pendingInspections.length > 0 && (
              <>
                <Divider theme={theme} />

                <div>
                  <h3>Serviço(s) Pendente(s)</h3>

                  {inspection.pendingInspections.map((product, index) => (
                    <ProductSelector
                      key={index}
                      index={index}
                      detailId={0}
                      report={product.report}
                      product={product.product}
                      obs={product.obs}
                      form={form}
                      setForm={setForm}
                      inspection={inspection}
                      setInspection={setInspection}
                      editing={editing}
                      list="pending"
                      loadInspectionData={loadInspectionData}
                    />
                  ))}
                </div>
              </>
            )}

            <Divider theme={theme} />

            <div>
              <h3>Serviço(s) Agendados(s)</h3>

              {form.reports.map((report, index) => (
                <ProductSelector
                  key={index}
                  index={index}
                  detailId={report.detailId}
                  report={report.report}
                  product={report.product}
                  obs={report.obs}
                  form={form}
                  setForm={setForm}
                  inspection={inspection}
                  setInspection={setInspection}
                  editing={editing}
                  list="scheduled"
                  loadInspectionData={loadInspectionData}
                />
              ))}
            </div>

            <Divider theme={theme} />

            <div>
              <h3>Responsáveis Técnicos</h3>

              <Row className="responsible">
                <span className="inspector">
                  <Label id="inspector" text="Vistoriador*" />
                  <Select
                    id="inspector"
                    placeholder="Selecione..."
                    icon={FaHelmetSafety}
                    enabled={editing && allowed && form.reports.length > 0}
                    list={inspectors}
                    selected={form.inspector}
                    required
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>

                <span className="coordinator">
                  <Label id="coordinator" text="Coordenador" />
                  <Select
                    id="coordinator"
                    placeholder="Selecione..."
                    icon={FiUser}
                    enabled={false}
                    list={coordinators}
                    selected={form.coordinator}
                    required
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>
            </div>

            <Divider theme={theme} />

            <div>
              <h3>Local da Vistoria</h3>

              <Address>
                <Row>
                  <span className="cep">
                    <Label id="cep" text="CEP" />
                    <Input
                      id="cep"
                      placeholder="CEP"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      value={form.cep}
                      maxLength="9"
                      minLength="9"
                      pattern="\d{5}-\d{3}"
                      onChange={(e) =>
                        enrollmentUtils.handleCep(e, form, setForm, getAddress)
                      }
                    />
                  </span>

                  <span className="uf">
                    <Label id="uf" text="Estado*" />
                    <Select
                      id="uf"
                      placeholder="Selecione..."
                      icon={BsHouse}
                      enabled={editing && allowed}
                      list={enrollmentUtils.states}
                      selected={form.uf}
                      required
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>
                </Row>

                <Row>
                  <span className="city">
                    <Label id="city" text="Cidade*" />
                    <Input
                      id="city"
                      placeholder="Cidade"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      required
                      value={form.city}
                      pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="neighborhood">
                    <Label id="neighborhood" text="Bairro" />
                    <Input
                      id="neighborhood"
                      placeholder="Bairro"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      value={form.neighborhood}
                      pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>

                <Row>
                  <span className="street">
                    <Label id="street" text="Logradouro" />
                    <Input
                      id="street"
                      placeholder="Logradouro"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      value={form.street}
                      pattern={
                        "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9 ]+$"
                      }
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="number">
                    <Label id="number" text="Número" />
                    <Input
                      id="number"
                      placeholder="Número"
                      type="number"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      value={form.number}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>

                <Row>
                  <span className="complement">
                    <Label id="complement" text="Complemento (opcional)" />
                    <Input
                      id="complement"
                      placeholder="Complemento"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      value={form.complement}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>
              </Address>
            </div>

            <Divider theme={theme} />

            <div>
              <h3>Data e Hora</h3>

              <Row className="desktop">
                <span className="date">
                  <Label id="startDate" text="Dias Reservados*" />
                  <DateRangePicker
                    id="startDate"
                    type="date-range"
                    icon={BsCalendarEvent}
                    disabled={!(editing && allowed)}
                    required
                    startDate={form.startDate}
                    endDate={form.endDate}
                    selectsRange
                    form={form}
                    setForm={setForm}
                    minDate={new Date()}
                    markedDates={
                      inspectionUtils.formatBusyDatesArray(
                        inspectorBusydates
                      ) || []
                    }
                    setDateConflictWarning={setDateConflictWarning}
                  />
                </span>

                <span className="date">
                  <Label id="date" text="Horário da Vistoria*" />
                  <DateTimePicker
                    id="date"
                    type="datetime"
                    icon={BsCalendarEvent}
                    disabled={!(editing && allowed)}
                    required
                    value={dayjs(form.date).format("DD/MM/YYYY - HH:mm")}
                    form={form}
                    setForm={setForm}
                    minDate={form.startDate}
                    maxDate={form.endDate}
                  />
                </span>
              </Row>

              <Row className="mobile">
                <span className="date">
                  <Label id="startDate" text="Dias Reservados*" />
                  <DateRangePicker
                    id="startDate"
                    type="date-range"
                    icon={BsCalendarEvent}
                    disabled={!(editing && allowed)}
                    required
                    startDate={form.startDate}
                    endDate={form.endDate}
                    selectsRange
                    form={form}
                    setForm={setForm}
                    minDate={new Date()}
                    markedDates={
                      inspectionUtils.formatBusyDatesArray(
                        inspectorBusydates
                      ) || []
                    }
                    setDateConflictWarning={setDateConflictWarning}
                  />
                </span>
              </Row>

              <Row className="mobile">
                <span className="date">
                  <Label id="date" text="Horário da Vistoria*" />
                  <DateTimePicker
                    id="date"
                    type="datetime"
                    icon={BsCalendarEvent}
                    disabled={!(editing && allowed)}
                    required
                    value={
                      form.date
                        ? dayjs(form.date).format("DD/MM/YYYY - HH:mm")
                        : null
                    }
                    form={form}
                    setForm={setForm}
                    minDate={new Date()}
                  />
                </span>
              </Row>
            </div>

            <ConfirmationMessage
              text="O vistoriador já tem compromissos agendados nestas datas. Tem certeza que deseja prosseguir?"
              action={setDateConflictWarning}
              confirming={dateConflictWarning}
              setConfirming={handleDateConflict}
              params={false}
              hideCloseButton={true}
            ></ConfirmationMessage>

            {editing && (
              <Button
                className="submit"
                title="Atualizar"
                enabled={editing && allowed}
              />
            )}
          </Form>
        </FormsWrapper>
      </Container>
    </Section>
  );
}

function ProductSelector({
  detailId,
  report,
  product,
  obs,
  index,
  form,
  setForm,
  inspection,
  setInspection,
  editing,
  list,
  loadInspectionData,
}) {
  const { deleteDetails } = useDeleteDetails();
  const { inspectionId } = useParams();
  const [removing, setRemoving] = useState(false);

  function handleComment(value) {
    if (list === "scheduled") {
      const reports = [...form.reports];

      reports[index] = {
        ...reports[index],
        obs: value,
      };

      setForm({
        ...form,
        reports,
      });
    } else {
      const reports = [...inspection.pendingInspections];

      reports[index] = {
        ...reports[index],
        obs: value,
      };

      setInspection({
        ...inspection,
        pendingInspections: reports,
      });
    }
  }

  function addProduct() {
    const reports = [...form.reports];
    const pendingInspections = [...inspection.pendingInspections];

    reports.push({
      detailId,
      report,
      product,
      obs,
    });

    pendingInspections.splice(index, 1);

    setForm({
      ...form,
      reports: reports,
    });

    setInspection({
      ...inspection,
      pendingInspections: pendingInspections,
    });
  }

  async function removeProduct(params) {
    try {
      if (detailId) await deleteDetails(params);

      loadInspectionData();

      setRemoving(false);

      toastUtils.toaster({
        message: "Serviço cancelado com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <>
      <ProductSelectorWrapper theme={theme}>
        <div>
          {editing && (
            <>
              {list === "scheduled" ? (
                <FaMinusCircle onClick={() => setRemoving(true)} />
              ) : (
                <FaPlusCircle onClick={addProduct} />
              )}
            </>
          )}

          <h5>{`${product.name} - ${report.name}`}</h5>
        </div>

        <ProductComment
          theme={theme}
          type="text"
          placeholder="Observações..."
          value={obs || ""}
          onChange={(e) => handleComment(e.target.value)}
          disabled={!editing}
          editing={editing}
        />
      </ProductSelectorWrapper>

      <ConfirmationMessage
        text={
          form.reports.length > 1
            ? "Tem certeza que deseja cancelar o agendamento deste serviço?"
            : "Não é possível cancelar todos os serviços de um agendamento!"
        }
        action={removeProduct}
        confirming={removing}
        setConfirming={setRemoving}
        params={{
          inspectionId,
          det: detailId,
        }}
        okMessage={form.reports.length > 1 ? false : true}
      ></ConfirmationMessage>
    </>
  );
}
