import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useVerificationTypes() {
  const token = useToken();

  const {
    data: verificationTypes,
    loading: verificationTypesLoading,
    error: verificationTypesError,
    act: getVerificationTypes,
  } = useAsync(
    (data) => inspectionApi.getVerificationTypes(data, token),
    false
  );

  return {
    verificationTypes,
    verificationTypesLoading,
    verificationTypesError,
    getVerificationTypes,
  };
}
