import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 0;
  padding: 0;

  & > div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow: hidden;
    background-color: ${({ theme, isDragActive, isDragAccept }) =>
      isDragAccept && isDragActive
        ? theme.COLORS.BACKGROUND_600
        : theme.COLORS.BACKGROUND_900};
    color: ${({ theme, isDragReject }) =>
      isDragReject ? theme.COLORS.ORANGE : theme.COLORS.GRAY_100};
    border-radius: 10px;
    border: 1px solid
      ${({ theme, isDragReject, error }) =>
        isDragReject || error
          ? theme.COLORS.ORANGE
          : theme.COLORS.BACKGROUND_700};
  }

  section {
    width: 100%;
    height: 100%;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  p {
    margin: 0;
    font-size: 18px;
  }

  svg {
    width: 60px;
    height: 60px;
    color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  }

  input {
    width: 100% !important;
    height: 100% !important;
    z-index: 1;
  }
`;

export const PreviewWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  iframe {
    width: calc(100% + 80px);
    height: 200px;
    border: 0;
    position: absolute;
    top: -42px;
    left: -20px;
    overflow: hidden;
  }

  & > div {
    width: 100%;
    height: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.COLORS.GRAY_TRANSLUCENT};
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: ${({ editing }) => (editing ? "default" : "pointer")};
    background: ${({ theme }) =>
      `linear-gradient(to top, ${theme.COLORS.BACKGROUND_900}, rgba(0, 0, 0, 0))`};
  }

  p {
    color: ${({ theme }) => theme.COLORS.WHITE};
    margin-bottom: 20px;
  }
`;

export const RemoveButton = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: ${({ theme }) => theme.COLORS.GRAY_100};
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.GRAY_50};

    svg {
      color: ${({ theme }) => theme.COLORS.BACKGROUND_600};
    }
  }

  svg {
    width: 10px;
    height: 10px;
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  }
`;
