import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Section } from "../../../../components/Section";
import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function IssuedCertificate() {
  const [title, setTitle] = useState("Certificações/Certificados Emitidos");
  const { certificateId } = useParams();
  const newCertificate = isNaN(certificateId);

  useEffect(() => {
    loadCertificateData();
  }, []);

  async function loadCertificateData() {
    if (newCertificate) setTitle(`${title}/Novo Certificado`);
  }

  return (
    <Container theme={theme}>
      <Section title={title}></Section>
    </Container>
  );
}
