import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiShipLine } from "react-icons/ri";
import { RxIdCard, RxDimensions, RxAngle } from "react-icons/rx";
import { GiHarborDock, GiCargoCrane } from "react-icons/gi";
import {
  AiOutlineFlag,
  AiOutlineCalendar,
  AiOutlineFormatPainter,
} from "react-icons/ai";
import { TbWeight } from "react-icons/tb";
import { SiTurbosquid } from "react-icons/si";
import { MdOutlineRoute } from "react-icons/md";
import { BiCube } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsHouse } from "react-icons/bs";

import { Section } from "../../../../components/Section";
import theme from "../../../../styles/theme";
import {
  AddSubitem,
  Address,
  Container,
  Edit,
  Form,
  FormsWrapper,
  Row,
} from "../styles";
import { Divider, Unit, VesselContainer } from "./styles";
import UserContext from "../../../../contexts/UserContext";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import useVessel from "../../../../hooks/api/vessel/useVessel";
import toastUtils from "../../../../utils/toast-utils";
import useSaveVessel from "../../../../hooks/api/vessel/useSaveVessel";
import useAdddress from "../../../../hooks/api/enrollment/useAddress";
import Label from "../../../../components/forms/enrollment-form/Label";
import Input from "../../../../components/forms/enrollment-form/Input";
import Button from "../../../../components/forms/enrollment-form/Button";
import Select from "../../../../components/forms/enrollment-form/Select";
import Engine from "../../../../components/forms/enrollment-form/Engine";
import generalUtils from "../../../../utils/general-utils";
import { IoAddCircleSharp } from "react-icons/io5";
import TextSelect from "../../../../components/forms/enrollment-form/TextSelect";
import Textarea from "../../../../components/forms/enrollment-form/Textarea";
import NextButton from "../../../../components/forms/enrollment-form/NextButton";

export default function Vessel() {
  const { vesselId } = useParams();
  const [newVessel, setNewVessel] = useState(isNaN(vesselId));
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const years = enrollmentUtils.generateYearOptions();
  const allowed =
    (userData.department.label === "COMERCIAL" ||
      userData.department.label === "DIRETORIA" ||
      userData.department.label === "ADMINISTRATIVO" ||
      userData.department.label === "FINANCEIRO") &&
    userData.permission.label !== "DPC";
  const { getVessel } = useVessel();
  const { saveVessel, saveVesselLoading } = useSaveVessel();
  const { getAddress } = useAdddress();
  const [title, setTitle] = useState(
    userData.permission.label !== "DPC" ? "Cadastro/Embarcações" : "Embarcações"
  );
  const [editing, setEditing] = useState(false);
  const [enrollment, setEnrollment] = useState({});
  const [engines, setEngines] = useState([enrollmentUtils.emptyEngine]);
  const [form, setForm] = useState(enrollmentUtils.emptyVessel);
  const [companyList, setCompanyList] = useState([]);
  const [types, setTypes] = useState({
    vesselTypes: [],
    navigationTypes: [],
    navigationAreas: [],
    vesselActivityServiceTypes: [],
    vesselClasses: [],
    typeAreaRelation: {},
  });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setNewVessel(isNaN(vesselId));
  }, [vesselId]);

  useEffect(() => {
    setEnrollment(form);
    loadVesselData();
  }, []);

  function handleForm({ name, value }) {
    if (name === "dockDoc") {
      const doc =
        enrollmentUtils.numberUnmask(value).length > 11
          ? enrollmentUtils.cnpjMask(value)
          : enrollmentUtils.cpfMask(value);
      return setForm({ ...form, [name]: doc });
    }

    if (name === "navigationType") {
      return setForm({
        ...form,
        [name]: value,
        navigationArea: { label: "", value: 0 },
      });
    }

    setForm({ ...form, [name]: value });
  }

  async function loadVesselData() {
    try {
      const response = await getVessel(vesselId);

      if (response) {
        setCompanyList(response.companyList);
        setTypes({
          ...types,
          vesselTypes: response.vesselTypes,
          navigationTypes: response.navigationTypes,
          navigationAreas: response.navigationAreas,
          vesselActivityServiceTypes: response.vesselActivityServiceTypes,
          vesselClasses: response.vesselClasses,
          typeAreaRelation: response.typeAreaRelation,
        });

        if (response.vessel?.engines.length > 0)
          setEngines(response.vessel.engines);

        if (newVessel) {
          setTitle(`${title}/Novo Cadastro`);
          setEditing(true);
          return;
        }

        const data = {
          ...response.vessel,
          propulsion: {
            value: response.vessel.propulsion,
            label: enrollmentUtils.propulsionList.find(
              (item) => item.value === response.vessel.propulsion
            ).label,
          },
          cep: enrollmentUtils.cepMask(response.vessel.cep),
          dockDoc:
            response.vessel.dockDoc.length > 11
              ? enrollmentUtils.cnpjMask(response.vessel.dockDoc)
              : enrollmentUtils.cpfMask(response.vessel.dockDoc),
        };

        setEnrollment(data);
        setForm(data);
        setTitle(`${title.replace("/Novo Cadastro", "")}/${data.name}`);
      } else {
        setForm(enrollmentUtils.emptyVessel);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);

    try {
      const body = {
        ...form,
        vesselId: newVessel ? 0 : Number(vesselId),
        company: form.company.value,
        vesselType: form.vesselType.value,
        navigationType: form.navigationType.value,
        navigationArea: form.navigationArea.value,
        vesselActivityServiceType: form.vesselActivityServiceType.value,
        vesselClass: form.vesselClass.value,
        propulsion: form.propulsion.value,
        year: form.year.value,
        flag: form.flag.value,
        uf: form.uf.value,
        dockDoc: enrollmentUtils.numberUnmask(form.dockDoc),
        cep: enrollmentUtils.numberUnmask(form.cep),
        n: Number(form.n) || null,
        n1: Number(form.n1) || null,
        n2: Number(form.n2) || null,
        netTonnage: Number(form.netTonnage),
        grossTonnage: Number(form.grossTonnage),
        engines: form.propulsion.value
          ? engines.map((engine) => ({
              ...engine,
              engineYear: engine.engineYear.value,
            }))
          : null,
      };

      const response = await saveVessel(body);

      setEditing(false);
      setTitle(
        `${title
          .replace("/Novo Cadastro", "")
          .replace(`/${response.name}`, "")}/${response.name}`
      );
      navigate(`/painel/cadastro/embarcacao/${response.vesselId}`);

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <Section title={title}>
        <VesselContainer theme={theme}>
          <FormsWrapper>
            {!newVessel && allowed && (
              <Edit
                theme={theme}
                onClick={(e) => {
                  e.preventDefault();

                  if (editing) setForm(enrollment);
                  else if (!allowed) {
                    toastUtils.toaster({
                      message:
                        "Você não tem permissão para editar os dados desta embarcação",
                      type: toastUtils.type.error,
                      position: toastUtils.position.topCenter,
                      theme: toastUtils.theme.colored,
                    });

                    return;
                  }

                  setEditing(!editing);
                }}
              >
                {editing ? "Cancelar" : "Editar"}
              </Edit>
            )}

            <Form className="vesselForm" onSubmit={handleSubmit}>
              <div>
                <h3>Dados do Contrato de Construção</h3>

                <Row>
                  <span className="name">
                    <Label id="name" text="Nome da Embarcação*" />
                    <Input
                      id="name"
                      placeholder="Nome da Embarcação"
                      type="text"
                      icon={RiShipLine}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={form.name}
                      pattern={
                        "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9 ]+$"
                      }
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="company">
                    <Label id="company" text="Armador*" />
                    <TextSelect
                      id="company"
                      placeholder="Selecione..."
                      icon={RxIdCard}
                      enabled={editing && allowed}
                      list={companyList}
                      selected={form.company}
                      required
                      submitted={submitted}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>
                </Row>

                <Row>
                  <span className="registration">
                    <Label id="registration" text="Número de Inscrição" />
                    <Input
                      id="registration"
                      placeholder="Número de Inscrição"
                      type="text"
                      icon={RxIdCard}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.registration}
                      pattern="^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9 /-]+$"
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="imo">
                    <Label id="imo" text="IMO" />
                    <Input
                      id="imo"
                      placeholder="IMO"
                      type="text"
                      icon={RxIdCard}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.imo}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="callSign">
                    <Label id="callSign" text="Indicativo de Chamada" />
                    <Input
                      id="callSign"
                      placeholder="Indicativo de Chamada"
                      type="text"
                      icon={RxIdCard}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.callSign}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>

                <Row>
                  <span className="vesselType">
                    <Label id="vesselType" text="Tipo de Embarcação*" />
                    <Select
                      id="vesselType"
                      placeholder="Selecione..."
                      icon={RiShipLine}
                      enabled={editing && allowed}
                      list={types.vesselTypes}
                      selected={form.vesselType}
                      required
                      submitted={submitted}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>

                  <span className="vesselActivityServiceType">
                    <Label
                      id="vesselActivityServiceType"
                      text="Tipo de Atividade/Serviço*"
                    />
                    <Select
                      id="vesselActivityServiceType"
                      placeholder="Selecione..."
                      icon={GiCargoCrane}
                      enabled={editing && allowed}
                      list={types.vesselActivityServiceTypes}
                      selected={form.vesselActivityServiceType}
                      required
                      submitted={submitted}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>
                </Row>

                <Row>
                  <span className="navigationType">
                    <Label id="navigationType" text="Tipo de Navegação*" />
                    <Select
                      id="navigationType"
                      placeholder="Selecione..."
                      icon={MdOutlineRoute}
                      enabled={editing && allowed}
                      list={types.navigationTypes}
                      selected={form.navigationType}
                      required
                      submitted={submitted}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>

                  <span className="navigationArea">
                    <Label id="navigationArea" text="Área de Navegação" />
                    <Select
                      id="navigationArea"
                      placeholder="Selecione..."
                      icon={MdOutlineRoute}
                      enabled={editing && allowed && form.navigationType.value}
                      list={types.navigationAreas.filter((area) =>
                        types.typeAreaRelation[form.navigationType.label]?.find(
                          (item) => item === area.label
                        )
                      )}
                      selected={form.navigationArea}
                      submitted={submitted}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>
                </Row>

                <Row>
                  <span className="previousOr">
                    <Label id="previousOr" text="OR anterior" />
                    <Input
                      id="previousOr"
                      placeholder="OR anterior"
                      type="text"
                      icon={RxIdCard}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.previousOr}
                      pattern={
                        "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9 ]+$"
                      }
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="registryPort">
                    <Label id="registryPort" text="Porto de Registro" />
                    <Input
                      id="registryPort"
                      placeholder="Porto de Registro"
                      type="text"
                      icon={GiHarborDock}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.registryPort}
                      pattern={
                        "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9 ]+$"
                      }
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>

                <Row>
                  <span className="specializedEntity">
                    <Label
                      id="specializedEntity"
                      text="Entidade Especializada"
                    />
                    <Input
                      id="specializedEntity"
                      placeholder="Entidade Especializada"
                      type="text"
                      icon={RxIdCard}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.specializedEntity}
                      pattern={
                        "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9 ]+$"
                      }
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="flag">
                    <Label id="flag" text="Bandeira*" />
                    <Select
                      id="flag"
                      placeholder="Selecione..."
                      icon={AiOutlineFlag}
                      enabled={editing && allowed}
                      list={generalUtils.nationalitiesList}
                      selected={form.flag}
                      submitted={submitted}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>

                  <span className="year">
                    <Label id="year" text="Ano de Construção*" />
                    <Select
                      id="year"
                      placeholder="Selecione..."
                      icon={AiOutlineCalendar}
                      enabled={editing && allowed}
                      list={years}
                      selected={form.year}
                      required
                      submitted={submitted}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>
                </Row>

                <Row>
                  <span className="vesselClass">
                    <Label id="vesselClass" text="Classe" />
                    <Select
                      id="vesselClass"
                      placeholder="Selecione..."
                      icon={RiShipLine}
                      enabled={editing && allowed}
                      list={types.vesselClasses}
                      selected={form.vesselClass}
                      submitted={submitted}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>

                  <span className="deadweight">
                    <Label id="deadweight" text="Porte Bruto (Pb)" />
                    <Input
                      id="deadweight"
                      placeholder="Porte Bruto"
                      type="text"
                      icon={TbWeight}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.deadweight}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>t</Unit>
                  </span>

                  <span className="grossTonnage">
                    <Label id="grossTonnage" text="Arqueação Bruta (AB)*" />
                    <Input
                      id="grossTonnage"
                      placeholder="Arqueação Bruta"
                      type="text"
                      icon={BiCube}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.grossTonnage}
                      required
                      patern={"^\\d+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: Number(
                            e.target.value.replace(/[^0-9]/g, "")
                          ).toString(),
                        })
                      }
                    />
                  </span>

                  <span className="netTonnage">
                    <Label id="netTonnage" text="Arqueação Líquida (AL)" />
                    <Input
                      id="netTonnage"
                      placeholder="Arqueação Líquida"
                      type="text"
                      icon={BiCube}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.netTonnage}
                      patern={"^\\d+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: Number(
                            e.target.value.replace(/[^0-9]/g, "")
                          ).toString(),
                        })
                      }
                    />
                  </span>
                </Row>
              </div>

              <Divider theme={theme} />

              <div>
                <h3>Estaleiro Construtor</h3>

                <Row>
                  <span className="dockName">
                    <Label id="dockName" text="Nome" />
                    <Input
                      id="dockName"
                      placeholder="Nome"
                      type="text"
                      icon={GiHarborDock}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.dockName}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="dockDoc">
                    <Label id="dockDoc" text="CPF/CNPJ" />
                    <Input
                      id="dockDoc"
                      placeholder="CPF/CNPJ"
                      type="text"
                      icon={RxIdCard}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.dockDoc}
                      onInput={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>

                <Address>
                  <Row>
                    <span className="cep">
                      <Label id="cep" text="CEP" />
                      <Input
                        id="cep"
                        placeholder="CEP"
                        type="text"
                        icon={BsHouse}
                        disabled={!(editing && allowed)}
                        submitted={submitted}
                        value={form.cep}
                        maxLength="9"
                        minLength="9"
                        pattern="\d{5}-\d{3}"
                        onChange={(e) =>
                          enrollmentUtils.handleCep(
                            e,
                            form,
                            setForm,
                            getAddress
                          )
                        }
                      />
                    </span>

                    <span className="uf">
                      <Label id="uf" text="Estado" />
                      <Select
                        id="uf"
                        placeholder="Selecione..."
                        icon={BsHouse}
                        enabled={editing && allowed}
                        list={enrollmentUtils.states}
                        selected={form.uf}
                        form={form}
                        setForm={setForm}
                        handleForm={handleForm}
                      />
                    </span>
                  </Row>

                  <Row>
                    <span className="city">
                      <Label id="city" text="Cidade" />
                      <Input
                        id="city"
                        placeholder="Cidade"
                        type="text"
                        icon={BsHouse}
                        disabled={!(editing && allowed)}
                        submitted={submitted}
                        value={form.city}
                        pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]+$"}
                        onChange={(e) =>
                          handleForm({
                            name: e.target.id,
                            value: e.target.value,
                          })
                        }
                      />
                    </span>

                    <span className="neighborhood">
                      <Label id="neighborhood" text="Bairro" />
                      <Input
                        id="neighborhood"
                        placeholder="Bairro"
                        type="text"
                        icon={BsHouse}
                        disabled={!(editing && allowed)}
                        submitted={submitted}
                        value={form.neighborhood}
                        pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]+$"}
                        onChange={(e) =>
                          handleForm({
                            name: e.target.id,
                            value: e.target.value,
                          })
                        }
                      />
                    </span>
                  </Row>

                  <Row>
                    <span className="street">
                      <Label id="street" text="Logradouro" />
                      <Input
                        id="street"
                        placeholder="Logradouro"
                        type="text"
                        icon={BsHouse}
                        disabled={!(editing && allowed)}
                        submitted={submitted}
                        value={form.street}
                        pattern={
                          "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9 ]+$"
                        }
                        onChange={(e) =>
                          handleForm({
                            name: e.target.id,
                            value: e.target.value,
                          })
                        }
                      />
                    </span>

                    <span className="number">
                      <Label id="number" text="Número" />
                      <Input
                        id="number"
                        placeholder="Número"
                        type="number"
                        icon={BsHouse}
                        disabled={!(editing && allowed)}
                        submitted={submitted}
                        value={form.number}
                        onChange={(e) =>
                          handleForm({
                            name: e.target.id,
                            value: e.target.value,
                          })
                        }
                      />
                    </span>
                  </Row>

                  <Row>
                    <span className="complement">
                      <Label id="complement" text="Complemento (opcional)" />
                      <Input
                        id="complement"
                        placeholder="Complemento"
                        type="text"
                        icon={BsHouse}
                        disabled={!(editing && allowed)}
                        submitted={submitted}
                        value={form.complement}
                        onChange={(e) =>
                          handleForm({
                            name: e.target.id,
                            value: e.target.value,
                          })
                        }
                      />
                    </span>
                  </Row>
                </Address>
              </div>

              <Divider theme={theme} />

              <div>
                <h3>Características Principais do Casco</h3>

                <Row>
                  <span className="totalLength">
                    <Label id="totalLength" text="Comprimento Total (Ct)" />
                    <Input
                      id="totalLength"
                      placeholder="Comprimento Total"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.totalLength}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>

                  <span className="lbp">
                    <Label
                      id="lbp"
                      text="Comprimento entre Perpendiculares (Lpp)"
                    />

                    <Input
                      id="lbp"
                      placeholder="Comprimento entre Perpendiculares"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.lbp}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>

                  <span className="waterlineLength">
                    <Label
                      id="waterlineLength"
                      text="Comprimento de Regra (L)"
                    />

                    <Input
                      id="waterlineLength"
                      placeholder="Comprimento de Regra"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.waterlineLength}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>
                </Row>

                <Row>
                  <span className="tonnageLength">
                    <Label
                      id="tonnageLength"
                      text="Comprimento de Arqueação (Ca)"
                    />

                    <Input
                      id="tonnageLength"
                      placeholder="Comprimento de Arqueação"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.tonnageLength}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>

                  <span className="bow">
                    <Label id="bow" text="Boca (B)" />
                    <Input
                      id="bow"
                      placeholder="Boca"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.bow}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>

                  <span className="draught">
                    <Label id="draught" text="Pontal (P)" />
                    <Input
                      id="draught"
                      placeholder="Pontal"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.draught}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>
                </Row>

                <Row>
                  <span className="draft">
                    <Label id="draft" text="Calado Moldado (H)" />
                    <Input
                      id="draft"
                      placeholder="Calado Moldado"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.draft}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>

                  <span className="loadedDraft">
                    <Label id="loadedDraft" text="Calado Carregado (Hc)" />
                    <Input
                      id="loadedDraft"
                      placeholder="Calado Carregado"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.loadedDraft}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>

                  <span className="lightDraft">
                    <Label id="lightDraft" text="Calado Leve (Hl)" />
                    <Input
                      id="lightDraft"
                      placeholder="Calado Leve"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.lightDraft}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>
                </Row>

                <Row>
                  <span className="hullShape">
                    <Label id="hullShape" text="Contorno (Co)" />

                    <Input
                      id="hullShape"
                      placeholder="Contorno"
                      type="text"
                      icon={RxDimensions}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.hullShape}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>

                  <span className="loadedDisplacement">
                    <Label
                      id="loadedDisplacement"
                      text="Deslocamento Carregado (Dc)"
                    />
                    <Input
                      id="loadedDisplacement"
                      placeholder="Deslocamento Carregado"
                      type="text"
                      icon={TbWeight}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.loadedDisplacement}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>t</Unit>
                  </span>

                  <span className="lightDisplacement">
                    <Label
                      id="lightDisplacement"
                      text="Deslocamento Leve (Dl)"
                    />
                    <Input
                      id="lightDisplacement"
                      placeholder="Deslocamento Leve"
                      type="text"
                      icon={TbWeight}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.lightDisplacement}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>t</Unit>
                  </span>
                </Row>

                <Row>
                  <span className="hullMaterial">
                    <Label id="hullMaterial" text="Material do Casco" />
                    <Input
                      id="hullMaterial"
                      placeholder="Material do Casco"
                      type="text"
                      icon={RiShipLine}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.hullMaterial}
                      pattern={
                        "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9 ]+$"
                      }
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="hullColor">
                    <Label id="hullColor" text="Cor do Casco" />
                    <Input
                      id="hullColor"
                      placeholder="Cor do Casco"
                      type="text"
                      icon={AiOutlineFormatPainter}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.hullColor}
                      pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="superstructureColor">
                    <Label
                      id="superstructureColor"
                      text="Cor da Superestrutura"
                    />
                    <Input
                      id="superstructureColor"
                      placeholder="Cor da Superestrutura"
                      type="text"
                      icon={AiOutlineFormatPainter}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.superstructureColor}
                      pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>

                <Row>
                  <span className="trim">
                    <Label id="trim" text="Trim" />
                    <Input
                      id="trim"
                      placeholder="Trim"
                      type="text"
                      icon={RxAngle}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.trim}
                      pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: generalUtils.precisionMask(e.target.value),
                        })
                      }
                    />

                    <Unit theme={theme}>m</Unit>
                  </span>

                  <span className="propulsion">
                    <Label id="propulsion" text="Propulsão*" />
                    <Select
                      id="propulsion"
                      placeholder="Selecione..."
                      icon={SiTurbosquid}
                      enabled={editing && allowed}
                      required
                      submitted={submitted}
                      list={enrollmentUtils.propulsionList}
                      selected={form.propulsion}
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>

                  <span className="desktop"></span>
                </Row>
              </div>

              {form.propulsion.value && (
                <>
                  <Divider theme={theme} />

                  {engines.map((item, index) => (
                    <Engine
                      key={index}
                      engineId={item.engineId}
                      engines={engines}
                      setEngines={setEngines}
                      index={index}
                      disabled={!(editing && allowed)}
                    />
                  ))}

                  {editing && allowed && (
                    <AddSubitem
                      theme={theme}
                      disabled={!(editing && allowed)}
                      onClick={() =>
                        setEngines([...engines, enrollmentUtils.emptyEngine])
                      }
                    >
                      <IoAddCircleSharp />
                      <p>Adicionar motor</p>
                    </AddSubitem>
                  )}
                </>
              )}

              <Divider theme={theme} />

              <div>
                <h3>Capacidade</h3>

                <Row>
                  <span className="n">
                    <Label id="n" text="Número de Tripulantes (N)" />
                    <Input
                      id="n"
                      placeholder="Número de Tripulantes"
                      type="text"
                      icon={HiOutlineUserGroup}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.n}
                      patern={"^\\d+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: Number(
                            e.target.value.replace(/[^0-9]/g, "")
                          ).toString(),
                        })
                      }
                    />
                  </span>

                  <span className="n1">
                    <Label id="n1" text="Passageiros em Camarotes (N1)" />
                    <Input
                      id="n1"
                      placeholder="Passageiros em Camarotes"
                      type="text"
                      icon={HiOutlineUserGroup}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.n1}
                      patern={"^\\d+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: Number(
                            e.target.value.replace(/[^0-9]/g, "")
                          ).toString(),
                        })
                      }
                    />
                  </span>

                  <span className="n2">
                    <Label id="n2" text="Demais Passageiros (N2)" />
                    <Input
                      id="n2"
                      placeholder="Demais Passageiros"
                      type="text"
                      icon={HiOutlineUserGroup}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={form.n2}
                      patern={"^\\d+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: Number(
                            e.target.value.replace(/[^0-9]/g, "")
                          ).toString(),
                        })
                      }
                    />
                  </span>
                </Row>
              </div>

              <Divider theme={theme} />

              <div>
                <h3>Observações</h3>

                <Row>
                  <span className="vessel-obs">
                    <Textarea
                      id="obs"
                      placeholder="Observações..."
                      disabled={!(editing && allowed)}
                      value={form.obs}
                      form={form}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>
              </div>

              <Button
                className="submit"
                title="Salvar"
                enabled={editing && allowed && !saveVesselLoading}
              />

              {newVessel && (
                <Button
                  className="cancel"
                  title="Cancelar"
                  enabled={editing && allowed && !saveVesselLoading}
                  type="button"
                  onClick={() => navigate(-1)}
                />
              )}

              {!editing && (
                <NextButton
                  text="Visualizar Documentos da Embarcação"
                  path={`/painel/embarcacoes/documentos/${vesselId}`}
                />
              )}
            </Form>
          </FormsWrapper>
        </VesselContainer>
      </Section>
    </Container>
  );
}
