import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useCertificates() {
  const token = useToken();

  const {
    data: certificates,
    loading: certificatesLoading,
    error: certificatesError,
    act: getCertificates,
  } = useAsync((data) => inspectionApi.getCertificates(data, token), false);

  return {
    certificates,
    certificatesLoading,
    certificatesError,
    getCertificates,
  };
}
