import { useEffect, useState } from "react";

import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function Input({ icon: Icon, submitted, ...rest }) {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (submitted) {
      if (
        rest.required &&
        (rest.value === "" || !rest.value?.toString().match(rest.pattern))
      )
        setError(true);
      else setError(false);
    } else {
      if (rest.value !== "" && !rest.value?.toString().match(rest.pattern))
        setError(true);
      else setError(false);
    }
  }, [submitted, rest.value]);

  return (
    <Container theme={theme} disabled={rest.disabled} error={error}>
      {Icon && <Icon size={20} className={rest.id} />}
      <input {...rest} />
    </Container>
  );
}
