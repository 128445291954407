import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  .submit,
  .cancel {
    position: absolute;
    bottom: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit {
    right: 0;
    width: 220px;
  }

  .cancel {
    right: 230px;
    width: 200px;
    background-color: ${({ theme }) => theme.COLORS.GRAY_100};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  }

  .update {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -60px;
    right: 0;
  }

  @media (max-width: 767px) {
    margin-top: 40px;

    .submit,
    .cancel {
      width: calc(50% - 5px);
    }

    .cancel {
      left: 0;
    }
  }
`;
