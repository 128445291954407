import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Section } from "../../../components/Section";
import generalUtils from "../../../utils/general-utils";
import { Container } from "./styles";
import Directories from "../../../components/Directories";
import theme from "../../../styles/theme";
import useVessel from "../../../hooks/api/vessel/useVessel";
import toastUtils from "../../../utils/toast-utils";

export default function Documents() {
  const search = useLocation().search.replace("?search=", "");
  const { vesselId } = useParams();
  const { getVessel } = useVessel();
  const [title, setTitle] = useState("Embarcações");

  const directories = [
    {
      text: "CERTIFICADOS",
      route: `/painel/embarcacoes/documentos/certificados/?vesselId=${vesselId}`,
    },
    {
      text: "RELATÓRIOS",
      route: `/painel/embarcacoes/documentos/relatorios/${vesselId}`,
    },
    {
      text: "DOCUMENTAÇÃO",
      route: `/painel/embarcacoes/documentos/documentacao/${vesselId}`,
    },
  ];
  const [dirList, setDirList] = useState(directories);

  useEffect(() => {
    loadVesselName();
  }, [vesselId]);

  useEffect(() => {
    generalUtils.handleDirSearch({ search, directories, setDirList });
  }, [search]);

  async function loadVesselName() {
    if (!vesselId) return;

    try {
      const response = await getVessel(vesselId);

      setTitle(`Embarcações/${response.vessel.name}/Documentos`);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        <Directories dirList={dirList} />
      </Container>
    </Section>
  );
}
