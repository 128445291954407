import Dropzone from "react-dropzone";
import { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { FaMinus } from "react-icons/fa";

import theme from "../../../../styles/theme";
import { Container, PreviewWrapper, RemoveButton } from "./styles";
import toastUtils from "../../../../utils/toast-utils";

export default function DropInput({
  file,
  setFile,
  setForm,
  editing,
  submitted,
  handlePdfView,
}) {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (submitted && !file) setError(true);
    else setError(false);
  }, [submitted, file]);

  function getFile(acceptedFiles, rejectedFiles) {
    if (rejectedFiles[0]) {
      let message = "Erro ao carregar arquivo!";
      const error = rejectedFiles[0].errors[0].code;

      if (error === "file-invalid-type") message += " Formato inválido.";
      if (error === "too-many-files")
        message += " Selecione apenas um arquivo.";

      return toastUtils.toaster({
        message,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }

    if (acceptedFiles[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setForm((prevForm) => ({
          ...prevForm,
          file: reader.result,
        }));
      };
      reader.readAsDataURL(acceptedFiles[0]);

      setFile(acceptedFiles[0]);
    }
  }

  return (
    <Dropzone
      onDrop={(acceptedFiles, rejectedFiles) =>
        getFile(acceptedFiles, rejectedFiles)
      }
      multiple={false}
      accept={{ "application/pdf": [".pdf"] }}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
      }) => (
        <Container
          theme={theme}
          isDragActive={isDragActive}
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
          error={error}
        >
          <div>
            {file ? (
              <FilePreview
                file={file}
                editing={editing}
                handlePdfView={handlePdfView}
              />
            ) : (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <>
                      {isDragAccept ? (
                        <p>Solte o arquivo aqui</p>
                      ) : (
                        <p>Formato de arquivo inválido</p>
                      )}
                    </>
                  ) : (
                    <p>
                      Arraste o arquivo PDF para cá ou clique para selecioná-lo
                    </p>
                  )}

                  <FiUpload />
                </div>
              </section>
            )}
          </div>

          {editing && file && (
            <RemoveButton
              type="button"
              onClick={() => setFile(null)}
              theme={theme}
            >
              <FaMinus />
            </RemoveButton>
          )}
        </Container>
      )}
    </Dropzone>
  );
}

function FilePreview({ file, editing, handlePdfView }) {
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (file) {
      if (file instanceof File) {
        const objectUrl = URL.createObjectURL(file);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
      } else setPreview(file.file);
    }
  }, [file]);

  return (
    <PreviewWrapper theme={theme} editing={editing}>
      <iframe src={preview} title={file.name} />

      <div onClick={handlePdfView}>
        <p>{file.name}</p>
      </div>
    </PreviewWrapper>
  );
}
